<template>
	<PopupThreeColWS name="bps"
	text="<h3>Bâtiment de production sud</h3><br/><p>Ce bâtiment accueille une des cinq salles de contrôle de la raffinerie. Son rôle est de piloter les unités d’épurations (hydrodésulfuration et usines à soufre) et le TER, ainsi que de gérer l'électricité, l'eau et la vapeur (appelées &laquo; utilités &raquo;). Chaque tuyau, chaque vanne, chaque quantité de produit, chaque opération est ainsi contrôlé et régulé. Afin de surveiller la raffinerie 24h/24h et 365j/365j, les salariés travaillent en équipe de quart. C’est-à-dire que leur rythme de travail alterne entre le matin, l’après-midi et la nuit.</p>"
	water="<p>L’eau : Environ 340 m3 / h provenant du service d'eau sont nécessaires pour assurer la réfrigération et le dessalage du pétrole brut, maintenir une réserve en cas d'incendie, alimenter les chaudières pour la production de vapeur et fournir le réseau d'eau sanitaire.</p>"
	steam="<p>La vapeur : La vapeur est utilisée pour les procédés de fabrication, le réchauffage des produits et des bacs de stockage, pour l'entraînement des turbines et, en cas d'incident, pour la sécurité des installations. Elle est donc indispensable au bon fonctionnement des unités.</p>"
	electricity="<p>L’électricité : La consommation d'électricité est d’environ 55 Mw / h. La majeure partie de nos besoins est fournie par RTE à 63 000 volts. Des équipements internes permettent ensuite de transformer cette puissance électrique selon les besoins du site.</p>">
	<picture slot="thumbnail__1">
		<img class="lazy"
		sizes="(max-width: 970px) 100vw, 970px"
		data-srcset="
		img/popup/BPS_jyvvue_c_scale_w_350.jpg 350w,
		img/popup/BPS_jyvvue_c_scale_w_706.jpg 706w,
		img/popup/BPS_jyvvue_c_scale_w_970.jpg 970w"
		data-src="img/popup/BPS_jyvvue_c_scale_w_970.jpg"
		src="static/logo-total.jpg"
		alt="">
	</picture>
</PopupThreeColWS>
</template>

<script>
	export default {
		components: {
			PopupThreeColWS: () => import('@/components/PopupThreeColWS.vue'),
		},
	}
</script>
